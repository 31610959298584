<template>
  <div id="layout-error">
    <h3>Erro 404</h3>
    <p>
      O recurso solicitado não foi encontrado
    </p>
  </div>
</template>

<script>
export default {
  name: 'LayoutError',
}
</script>

<style scoped>

</style>